import * as NetworkServices from "../services/networkServices";
import * as Endpoints from "../const/endpoints";
import { downloadFile } from "../helper/fileHelper";

// Actions

const GET_ALL_LOGS = "reducer/GET_ALL_LOGS";
const GET_LOGS = "reducer/GET_LOGS";
const GET_LOG_BY_ID = "reducer/GET_LOG_BY_ID";

// Reducers

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_LOGS:
      return {
        ...state,
        allAdminAuditLogs: action.allAdminAuditLogs,
        loadingGetAllAdminAuditLogs: action.loadingGetAllAdminAuditLogs,
        error: action.error,
      };
    case GET_LOGS:
      return {
        ...state,
        adminAuditLogs: action.adminAuditLogs,
        loadingGetAllAdminAuditLogs: action.loadingGetAllAdminAuditLogs,
        error: action.error,
      };
    case GET_LOG_BY_ID:
      return {
        ...state,
        currentAdmintAuditLog: action.currentAdmintAuditLog,
        loadingCurrentAdmintAuditLog: action.loadingCurrentAdmintAuditLog,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

// Action Creators

export function getAllAuditLogs() {
  return function(dispatch) {
    dispatch({
      type: GET_ALL_LOGS,
      loadingGetAllAdminAuditLogs: true,
      error: {
        status: false,
        message: "",
      },
    });
    return NetworkServices.requestData(
      "GET",
      Endpoints.getAllAuditLogs,
      null,
      "text/csv",
      "text"
    )
      .then((response) => {
        downloadFile(response.data, "adminAuditLog.csv", "text/csv");
        dispatch({
          type: GET_ALL_LOGS,
          loadingGetAllAdminAuditLogs: false,
          error: {
            status: false,
            message: "",
          },
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_LOGS,
          loadingGetAllAdminAuditLogs: false,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function getAuditLogs(month, year) {
  return function(dispatch) {
    dispatch({
      type: GET_LOGS,
      loadingGetAllAdminAuditLogs: true,
      error: {
        status: false,
        message: "",
      },
    });
    return NetworkServices.requestData(
      "GET",
      Endpoints.getAuditLogs + "?month=" + month + "&year=" + year,
      null,
      "text/csv",
      "text"
    )
      .then((response) => {
        downloadFile(response.data, "adminAuditLog.csv", "text/csv");
        dispatch({
          type: GET_LOGS,
          loadingGetAllAdminAuditLogs: false,
          error: {
            status: false,
            message: "",
          },
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_LOGS,
          loadingGetAllAdminAuditLogs: false,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
