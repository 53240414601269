import * as NetworkServices from "../services/networkServices";
import * as Endpoints from "../const/endpoints";
import { feedbackPageSize, paginationModel } from "../const/global";
import _ from "lodash";
import { handleValidationError } from "./modal";

// Actions

const CHANGE_FEEDBACK_ACTIVE_PAGE = "reducer/CHANGE_FEEDBACK_ACTIVE_PAGE";
const GET_GENERAL_FEEDBACKS = "reducer/GET_GENERAL_FEEDBACKS";
const CLEAR_PAGINATION = "reducer/CLEAR_PAGINATION";
const GET_AVERAGE_GENERAL_FEEDBACK = "reducer/GET_AVERAGE_GENERAL_FEEDBACK";

// Reducers

export default (
  state = {
    allGeneralFeedbacks: [],
    loadingGeneralFeedbacks: false,
    loadingAverageGeneralFeedback: false,
    pagination: paginationModel,
  },
  action
) => {
  switch (action.type) {
    case GET_GENERAL_FEEDBACKS:
      return {
        ...state,
        loadingGeneralFeedbacks: action.loadingGeneralFeedbacks,
        pagination: action.pagination,
        allGeneralFeedbacks: action.allGeneralFeedbacks,
      };
    case GET_AVERAGE_GENERAL_FEEDBACK:
      return {
        ...state,
        loadingAverageGeneralFeedback: action.loadingAverageGeneralFeedback,
        averageGeneralFeedback: action.averageGeneralFeedback,
      };
    case CHANGE_FEEDBACK_ACTIVE_PAGE:
      return {
        ...state,
        pagination: action.pagination,
      };
    case CLEAR_PAGINATION:
      return {
        ...state,
        pagination: paginationModel,
      };
    default:
      return {
        ...state,
      };
  }
};

// Action Creators
export function getPagedGeneralFeedbacks() {
  return function(dispatch, getState) {
    dispatch({
      type: GET_GENERAL_FEEDBACKS,
      loadingGeneralFeedbacks: true,
      allGeneralFeedbacks: [],
      pagination: _.cloneDeep(getState().feedback.pagination),
    });

    let currentPage = getState().feedback.pagination.currentPage;

    const requestBody = {
      page: currentPage,
      pageSize: feedbackPageSize,
    };
    dispatch(handleValidationError(false));

    NetworkServices.requestData(
      "POST",
      Endpoints.getPagedGeneralFeedbacks,
      requestBody,
      false,
      false
    )
      .then((response) => {
        if (
          response.data.code === 1 &&
          response.data.data.results !== undefined
        ) {
          return dispatch({
            type: GET_GENERAL_FEEDBACKS,
            allGeneralFeedbacks: response.data.data.results,
            loadingGeneralFeedbacks: false,
            error: {
              status: false,
            },
            pagination: {
              currentPage: response.data.data.currentPage,
              pageCount: response.data.data.pageCount,
              pageSize: response.data.data.pageSize,
              rowCount: response.data.data.rowCount,
              firstRowOnPage: response.data.data.firstRowOnPage,
              lastRowOnPage: response.data.data.lastRowOnPage,
            },
          });
        } else {
          return dispatch({
            type: GET_GENERAL_FEEDBACKS,
            loadingGeneralFeedbacks: false,
            allGeneralFeedbacks: [],
            error: {
              status: true,
              message: "Error: " + response.data.message,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_GENERAL_FEEDBACKS,
          loadingGeneralFeedbacks: false,
          allGeneralFeedbacks: [],
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function getaverageGeneralFeedback() {
  return function(dispatch, getState) {
    dispatch({
      type: GET_AVERAGE_GENERAL_FEEDBACK,
      loadingAverageGeneralFeedback: true,
      averageGeneralFeedback: {},
    });

    let averageGeneralFeedback = {};
    return NetworkServices.requestData(
      "GET",
      Endpoints.getaverageGeneralFeedback,
      null,
      false,
      false
    )
      .then((response) => {
        if (
          response.data.code === 1 &&
          response.data.data !== undefined &&
          response.data.data !== null
        ) {
          averageGeneralFeedback = response.data.data;
          return dispatch({
            type: GET_AVERAGE_GENERAL_FEEDBACK,
            loadingAverageGeneralFeedback: false,
            averageGeneralFeedback: averageGeneralFeedback,
          });
        } else {
          return dispatch({
            type: GET_AVERAGE_GENERAL_FEEDBACK,
            loadingAverageGeneralFeedback: false,
            averageGeneralFeedback: {},
            error: {
              status: true,
              message: "Error: " + response.data.message,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_AVERAGE_GENERAL_FEEDBACK,
          loadingAverageGeneralFeedback: false,
          averageGeneralFeedback: {},
          error: {
            status: true,
            message: "Error" + error,
          },
        });
      });
  };
}

export function changeActivePage(page) {
  return function(dispatch, getState) {
    if (page !== getState().feedback.pagination.currentPage) {
      let paginationObj = getState().feedback.pagination;
      paginationObj.currentPage = page;
      return dispatch({
        type: CHANGE_FEEDBACK_ACTIVE_PAGE,
        pagination: paginationObj,
      });
    }
  };
}

export function clearPagination() {
  return function(dispatch) {
    return dispatch({
      type: CLEAR_PAGINATION,
    });
  };
}
