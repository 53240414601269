import * as NetworkServices from "../services/networkServices";
import * as Endpoints from "../const/endpoints";
import { responseCode } from "../const/responseCode";
import { removeMultipleSpaces } from "../helper/stringHelper";
import { availableTimes } from "../const/models/branchModel";
import _ from "lodash";

// Actions

const GET_COMPANIES = "reducer/GET_COMPANIES";
const UPDATE_COMPANY = "reducer/UPDATE_COMPANY";
const DELETE_COMPANY = "reducer/DELETE_COMPANY";
const ADD_COMPANY = "reducer/ADD_COMPANY";

const CHANGE_SELECTED_COMPANY = "reducer/CHANGE_SELECTED_COMPANY";
const EDIT_COMPANY = "reducer/EDIT_COMPANY";

const GET_BRANCHES = "reducer/GET_BRANCHES";
const UPDATE_BRANCH = "reducer/UPDATE_BRANCH";
const DELETE_BRANCH = "reducer/DELETE_BRANCH";
const ADD_BRANCH = "reducer/ADD_BRANCH";

const CHANGE_SELECTED_BRANCH = "reducer/CHANGE_SELECTED_BRANCH";
const EDIT_BRANCH = "reducer/EDIT_BRANCH";
const CLONE_SELECTED_BRANCH = "reducer/CLONE_SELECTED_BRANCH";

const RESET_STORE = "reducer/RESET_STORE";

// Reducers
const initialState = {
  companyList: [],
  branchList: [],
  selectedCompany: null,
  editedCompany: null,
  selectedBranch: null,
  editedBranch: null,
  branchEditPageIsOpen: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STORE:
      return {
        companyList: [],
        branchList: [],
        selectedCompany: null,
        editedCompany: null,
        selectedBranch: null,
        editedBranch: null,
        branchEditPageIsOpen: false,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companyList: action.companyList,
        branchList: action.branchList,
        selectedCompany: action.selectedCompany,
        editedCompany: action.editedCompany,
        loadingGetCompanies: action.loadingGetCompanies,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        loadingUpdateCompany: action.loadingUpdateCompany,
        isCompanyUpdated: action.isCompanyUpdated,
        editedCompany: action.editedCompany,
        selectedCompany: _.cloneDeep(action.editedCompany),
      };
    case DELETE_COMPANY:
      return {
        ...state,
        loadingDeleteCompany: action.loadingDeleteCompany,
        isCompanyDeleted: action.isCompanyDeleted,
      };
    case ADD_COMPANY:
      return {
        ...state,
        loadingAddCompany: action.loadingAddCompany,
      };
    case CHANGE_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.selectedCompany,
        editedCompany: action.editedCompany,
        isCompanyUpdated: action.isCompanyUpdated,
      };
    case EDIT_COMPANY: {
      return {
        ...state,
        editedCompany: action.editedCompany,
      };
    }
    case GET_BRANCHES:
      return {
        ...state,
        branchList: action.branchList,
        loadingGetBranches: action.loadingGetBranches,
      };
    case UPDATE_BRANCH:
      return {
        ...state,
        loadingUpdateBranch: action.loadingUpdateBranch,
        isBranchUpdated: action.isBranchUpdated,
        editedBranch: action.editedBranch,
        selectedBranch: _.cloneDeep(action.editedBranch),
      };
    case DELETE_BRANCH:
      return {
        ...state,
        loadingDeleteBranch: action.loadingDeleteBranch,
        isBranchDeleted: action.isBranchDeleted,
      };
    case ADD_BRANCH:
      return {
        ...state,
        loadingAddBranch: action.loadingAddBranch,
        isBranchAdded: action.isBranchAdded,
      };
    case CHANGE_SELECTED_BRANCH:
      return {
        ...state,
        selectedBranch: action.selectedBranch,
        editedBranch: action.editedBranch,
        isBranchUpdated: action.isBranchUpdated,
      };
    case EDIT_BRANCH:
      return {
        ...state,
        editedBranch: action.editedBranch,
      };
    case CLONE_SELECTED_BRANCH:
      return {
        ...state,
        branchList: action.branchList,
      };
    default:
      return {
        ...state,
      };
  }
};

export function resetCompanyStore() {
  return function(dispatch) {
    dispatch({
      type: RESET_STORE,
    });
  };
}

// COMPANY
export function getCompanies() {
  return function(dispatch, getState) {
    const storeCompany = getState().company;
    dispatch({
      type: GET_COMPANIES,
      loadingGetCompanies: true,
      companyList: storeCompany.companyList,
      selectedCompany: storeCompany.selectedCompany,
      editedCompany: storeCompany.editedCompany,
    });
    return NetworkServices.requestData(
      "GET",
      Endpoints.companyEndpoint,
      null,
      false,
      false
    )
      .then((response) => {
        const resData = response.data;
        if (resData.code === responseCode.success) {
          if (resData.data.length > 0) {
            return dispatch({
              type: GET_COMPANIES,
              companyList: resData.data,
              branchList: storeCompany.branchList,
              editedCompany: resData.data[0],
              selectedCompany: resData.data[0],
              loadingGetCompanies: false,
            });
          } else {
            return dispatch({
              type: GET_COMPANIES,
              companyList: [],
              branchList: [],
              editedCompany: null,
              selectedCompany: null,
              loadingGetCompanies: false,
            });
          }
        } else {
          return dispatch({
            type: GET_COMPANIES,
            loadingGetCompanies: false,
            companyList: storeCompany.companyList,
            branchList: storeCompany.branchList,
            editedCompany: storeCompany.editedCompany,
            selectedCompany: storeCompany.selectedCompany,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_COMPANIES,
          loadingGetCompanies: false,
          companyList: storeCompany.companyList,
          branchList: storeCompany.branchList,
          editedCompany: storeCompany.editedCompany,
          selectedCompany: storeCompany.selectedCompany,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
export function updateCompany() {
  return function(dispatch, getState) {
    const storeCompany = getState().company;
    const editedCompany = removeMultipleSpaces(storeCompany.editedCompany);
    dispatch({
      type: UPDATE_COMPANY,
      loadingUpdateCompany: true,
      isCompanyUpdated: false,
      editedCompany: editedCompany,
    });
    return NetworkServices.requestData(
      "PUT",
      Endpoints.companyEndpoint,
      editedCompany,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          return dispatch({
            type: UPDATE_COMPANY,
            loadingUpdateCompany: false,
            isCompanyUpdated: true,
            editedCompany: response.data.data,
          });
        } else {
          return dispatch({
            type: UPDATE_COMPANY,
            loadingUpdateCompany: false,
            isCompanyUpdated: false,
            editedCompany: editedCompany,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_COMPANY,
          loadingUpdateCompany: false,
          isCompanyUpdated: false,
          editedCompany: editedCompany,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
export function deleteCompany(companyId) {
  return function(dispatch) {
    dispatch({
      type: DELETE_COMPANY,
      loadingDeleteCompany: true,
      isCompanyDeleted: false,
    });
    return NetworkServices.requestData(
      "DELETE",
      Endpoints.companyEndpoint + "?id=" + companyId,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          dispatch(getCompanies());
          return dispatch({
            type: DELETE_COMPANY,
            loadingDeleteCompany: false,
            isCompanyDeleted: true,
          });
        } else {
          return dispatch({
            type: DELETE_COMPANY,
            loadingDeleteCompany: false,
            isCompanyDeleted: false,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_COMPANY,
          loadingDeleteCompany: false,
          isCompanyDeleted: false,
        });
      });
  };
}
export function addCompany() {
  return function(dispatch, getState) {
    const storeCompany = getState().company;
    const newCompany = removeMultipleSpaces(storeCompany.editedCompany);
    dispatch({
      type: ADD_COMPANY,
      loadingAddCompany: true,
      companySuccessfullyAdded: false,
    });
    return NetworkServices.requestData(
      "POST",
      Endpoints.companyEndpoint,
      newCompany,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          dispatch(getCompanies());
          return dispatch({
            type: ADD_COMPANY,
            loadingAddCompany: false,
            companySuccessfullyAdded: true,
          });
        } else {
          return dispatch({
            type: ADD_COMPANY,
            loadingAddCompany: false,
            companySuccessfullyAdded: false,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: ADD_COMPANY,
          loadingAddCompany: false,
          companySuccessfullyAdded: false,
        });
      });
  };
}
export function changeSelectedCompany(newSelectedCompany) {
  return function(dispatch) {
    return dispatch({
      type: CHANGE_SELECTED_COMPANY,
      selectedCompany: _.cloneDeep(newSelectedCompany),
      editedCompany: _.cloneDeep(newSelectedCompany),
      isCompanyUpdated: false,
    });
  };
}
export function editCompany(value, name) {
  return function(dispatch, getState) {
    const editedCompany = getState().company.editedCompany;
    value === "" && (value = null);
    editedCompany[name] = value;
    return dispatch({
      type: EDIT_COMPANY,
      editedCompany: _.cloneDeep(editedCompany),
    });
  };
}

// BRANCH
export function getBranches() {
  return function(dispatch, getState) {
    const companyId = getState().company.selectedCompany.id;
    dispatch({
      type: GET_BRANCHES,
      loadingGetBranches: true,
      branchList: [],
    });
    return NetworkServices.requestData(
      "GET",
      Endpoints.branchEndpoint + "?companyId=" + companyId,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          let branchList = response.data.data;
          branchList.map((branch) => {
            branch.hasFlexibleBusinessHour =
              branch.availableTimes == null || branch.availableTimes.length == 0
                ? true
                : false;
            branch.availableTimes = _.orderBy(
              branch.availableTimes,
              "dayOfWeek",
              "asc"
            );
            return branch;
          });
          return dispatch({
            type: GET_BRANCHES,
            loadingGetBranches: false,
            branchList: branchList,
          });
        } else {
          return dispatch({
            type: GET_BRANCHES,
            loadingGetBranches: false,
            branchList: getState().company.branchList,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_BRANCHES,
          loadingGetBranches: false,
          branchList: getState().company.branchList,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
export function updateBranch() {
  return function(dispatch, getState) {
    const storeCompany = getState().company;
    const editedBranch = removeMultipleSpaces(storeCompany.editedBranch);
    if (editedBranch.hasFlexibleBusinessHour) {
      editedBranch.availableTimes = null;
    }
    dispatch({
      type: UPDATE_BRANCH,
      loadingUpdateBranch: true,
      isBranchUpdated: false,
      editedBranch: editedBranch,
    });
    return NetworkServices.requestData(
      "PUT",
      Endpoints.branchEndpoint,
      editedBranch,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          var responseBranch = response.data.data;
          responseBranch.hasFlexibleBusinessHour =
            responseBranch.availableTimes == null ||
            responseBranch.availableTimes.length == 0
              ? true
              : false;
          return dispatch({
            type: UPDATE_BRANCH,
            loadingUpdateBranch: false,
            isBranchUpdated: true,
            editedBranch: responseBranch,
          });
        } else {
          return dispatch({
            type: UPDATE_BRANCH,
            loadingUpdateBranch: false,
            isBranchUpdated: false,
            editedBranch: editedBranch,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_BRANCH,
          loadingUpdateBranch: false,
          editedBranch: editedBranch,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
export function deleteBranch(branchId) {
  return function(dispatch) {
    dispatch({
      type: DELETE_BRANCH,
      loadingDeleteBranch: true,
      isBranchDeleted: false,
    });
    return NetworkServices.requestData(
      "DELETE",
      Endpoints.branchEndpoint + "?id=" + branchId,
      null,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          dispatch(getCompanies());
          return dispatch({
            type: DELETE_BRANCH,
            loadingDeleteBranch: false,
            isBranchDeleted: true,
          });
        } else {
          return dispatch({
            type: DELETE_BRANCH,
            loadingDeleteBranch: false,
            isBranchDeleted: false,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_BRANCH,
          loadingDeleteBranch: false,
          isBranchDeleted: false,
        });
      });
  };
}
export function addBranch() {
  return function(dispatch, getState) {
    const storeCompany = getState().company;
    const newBranch = removeMultipleSpaces(storeCompany.editedBranch);
    if (newBranch.hasFlexibleBusinessHour) {
      newBranch.availableTimes = null;
    }
    const companyId = storeCompany.selectedCompany.id;
    dispatch({
      type: ADD_BRANCH,
      loadingAddBranch: true,
      isBranchAdded: false,
    });
    return NetworkServices.requestData(
      "POST",
      Endpoints.branchEndpoint + "?companyId=" + companyId,
      newBranch,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          return dispatch({
            type: ADD_BRANCH,
            loadingAddBranch: false,
            isBranchAdded: true,
          });
        } else {
          return dispatch({
            type: ADD_BRANCH,
            loadingAddBranch: false,
            isBranchAdded: false,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: ADD_BRANCH,
          loadingAddBranch: false,
          isBranchAdded: false,
        });
      });
  };
}
export function changeSelectedBranch(newSelectedBranch) {
  return function(dispatch) {
    return dispatch({
      type: CHANGE_SELECTED_BRANCH,
      selectedBranch: _.cloneDeep(newSelectedBranch),
      editedBranch: _.cloneDeep(newSelectedBranch),
      isBranchUpdated: false,
    });
  };
}
export function editBranch(value, name, index) {
  return function(dispatch, getState) {
    let editedBranch = getState().company.editedBranch;
    value === "" && (value = null);
    if (index) {
      value === null && (value = "06:00");
      let editedDay = editedBranch.availableTimes.find((item) => {
        return item.dayOfWeek === index;
      });
      editedDay[name] = value;
    } else {
      editedBranch[name] = value;
      name === "id" && (editedBranch = _.omit(editedBranch, "id"));
      if (
        name == "hasFlexibleBusinessHour" &&
        value === false &&
        (editedBranch.availableTimes == null ||
          editedBranch.availableTimes.length == 0)
      ) {
        editedBranch.availableTimes = availableTimes;
      }
    }
    return dispatch({
      type: EDIT_BRANCH,
      editedBranch: _.cloneDeep(editedBranch),
    });
  };
}

export function cloneBranch(id) {
  return function(dispatch, getState) {
    let { branchList } = getState().company;
    let clonedBranch = _.cloneDeep(
      branchList.filter((branch) => branch.id === id)[0]
    );
    clonedBranch.id = null;
    branchList.push(clonedBranch);
    return dispatch({
      type: CLONE_SELECTED_BRANCH,
      branchList: branchList,
    });
  };
}
