import * as Endpoints from "../const/endpoints";
import * as NetworkServices from "../services/networkServices";
// Actions

const UPLOAD_FILE = "reducer/UPLOAD_FILE";
const UPLOAD_INVOICE = "reducer/UPLOAD_INVOICE";

// Reducers

export default (
  state = {
    error: {
      status: false,
      message: "",
    },
  },
  action
) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        loadingUploadFile: action.loadingUploadFile,
        hashOfImages: action.hashOfImages,
        error: action.error,
      };
    case UPLOAD_INVOICE:
      return {
        ...state,
        loadingUploadInvoice: action.loadingUploadInvoice,
        hashOfInvoices: action.hashOfInvoices,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

// Action Creators

export function uploadFile(formData) {
  return function(dispatch) {
    dispatch({
      type: UPLOAD_FILE,
      loadingUploadFile: true,
      error: {
        status: false,
        message: "",
      },
    });
    return NetworkServices.requestData(
      "POST",
      Endpoints.uploadImages,
      formData,
      "form-data",
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          return dispatch({
            type: UPLOAD_FILE,
            loadingUploadFile: false,
            hashOfImages: response.data.data,
            error: {
              status: false,
              message: "",
            },
          });
        } else {
          return dispatch({
            type: UPLOAD_FILE,
            loadingUploadFile: false,
            error: {
              status: true,
              message: "Cannot save file",
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPLOAD_FILE,
          loadingUploadFile: false,
          error: {
            status: true,
            message: "Cannot save file" + error,
          },
        });
      });
  };
}

export function uploadInvoices(formData) {
  return function(dispatch) {
    dispatch({
      type: UPLOAD_INVOICE,
      loadingUploadInvoice: true,
      error: {
        status: false,
        message: "",
      },
    });
    return NetworkServices.requestData(
      "POST",
      Endpoints.uploadInvoices,
      formData,
      "form-data",
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          return dispatch({
            type: UPLOAD_INVOICE,
            loadingUploadInvoice: false,
            hashOfInvoices: response.data.data,
            error: {
              status: false,
              message: "",
            },
          });
        } else {
          return dispatch({
            type: UPLOAD_INVOICE,
            loadingUploadInvoice: false,
            error: {
              status: true,
              message: "Cannot save file",
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPLOAD_INVOICE,
          loadingUploadInvoice: false,
          error: {
            status: true,
            message: "Cannot save file" + error,
          },
        });
      });
  };
}
