import * as NetworkServices from "../services/networkServices";
import * as Endpoints from "../const/endpoints";
import {
  customerDatabasePageSize,
  customerDBSearch,
  paginationModel,
} from "../const/global";
import _ from "lodash";
// import { stat } from 'fs-extra';

// Actions

const HANDLE_CHANGE = "reducer/HANDLE_CHANGE";
const GET_CUSTOMERS = "reducer/GET_CUSTOMERS";
const UPDATE_CUSTOMER = "reducer/UPDATE_CUSTOMER";
const DELETE_CUSTOMER = "reducer/DELETE_CUSTOMER";
const SHOW_CANNOT_DELETE_ACTIVE_USER_ERROR =
  "reducer/SHOW_CANNOT_DELETE_ACTIVE_USER_ERROR";
const CHANGE_ACTIVE_PAGE = "reducer/CHANGE_ACTIVE_PAGE";
const CHANGE_SEARCH_TERM = "reducer/CHANGE_SEARCH_TERM";
const CLEAR_SEARCH_FILTER = "reducer/CLEAR_SEARCH_FILTER";
const CLEAR_CUSTOMER_DB_LIST = "reducer/CLEAR_CUSTOMER_DB_LIST";
const CLEAR_CUSTOMER = "reducer/CLEAR_CUSTOMER";
const CLEAR_PAGINATION = "reducer/CLEAR_PAGINATION";
// Reducers

export default (
  state = {
    customerList: [],
    customerListDB: [],
    userDeleted: false,
    userUpdated: false,
    showUserNotBeDeleted: false,
    showUserNotBeUpdated: false,
    showCannotDeleteActiveUsersError: false,
    pagination: paginationModel,
    customerDBSearchModel: customerDBSearch,
    searchObject: null,
    clearFilterButtonClicked: false,
  },
  action
) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return {
        ...state,
        customerList: _.cloneDeep(action.customerList),
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customerList: _.cloneDeep(action.customerList),
        customerListDB: action.customerListDB,
        loadingGetCustomers: action.loadingGetCustomers,
        userDeleted: _.cloneDeep(action.userDeleted),
        userUpdated: _.cloneDeep(action.userUpdated),
        showUserNotBeDeleted: action.showUserNotBeDeleted,
        showUserNotBeUpdated: action.showUserNotBeUpdated,
        showCannotDeleteActiveUsersError:
          action.showCannotDeleteActiveUsersError,
        error: action.error,
        pagination: action.pagination,
        clearFilterButtonClicked: action.clearFilterButtonClicked,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        loadingUpdateCustomers: action.loadingUpdateCustomers,
        userUpdated: action.userUpdated,
        showUserNotBeUpdated: action.showUserNotBeUpdated,
        error: action.error,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        loadingDeleteCustomer: action.loadingDeleteCustomer,
        loadingGetCustomers: action.loadingGetCustomers,
        userDeleted: action.userDeleted,
        showUserNotBeDeleted: action.showUserNotBeDeleted,
        searchObject: action.searchObject,
        error: action.error,
      };
    case SHOW_CANNOT_DELETE_ACTIVE_USER_ERROR:
      return {
        ...state,
        showCannotDeleteActiveUsersError: _.cloneDeep(
          action.showCannotDeleteActiveUsersError
        ),
        loadingDeleteCustomer: action.loadingDeleteCustomer,
        loadingGetCustomers: action.loadingGetCustomers,
      };
    case CHANGE_ACTIVE_PAGE:
      return {
        ...state,
        pagination: action.pagination,
      };
    case CHANGE_SEARCH_TERM:
    case CLEAR_SEARCH_FILTER:
      return {
        ...state,
        searchObject: action.searchObject,
        customerDBSearchModel: action.customerDBSearchModel,
        clearFilterButtonClicked: action.clearFilterButtonClicked,
      };
    case CLEAR_CUSTOMER_DB_LIST:
      return {
        ...state,
        customerListDB: [],
      };
    case CLEAR_PAGINATION:
      return {
        ...state,
        pagination: paginationModel,
      };
    case CLEAR_CUSTOMER:
      return {
        ...state,
        customerList: [],
      };
    default:
      return {
        ...state,
      };
  }
};

export function handleChangeCustomerDatabase(id, name, value) {
  return function(dispatch, getState) {
    let editedCustomerList = getState().customerDB.customerList;
    editedCustomerList.forEach((customer) => {
      if (customer.userId === id) {
        if (name === "membershipStatus") {
          value === "true" || value === true
            ? (customer[name] = true)
            : (customer[name] = false);
        } else {
          customer[name] = value;
        }
      }
    });
    return dispatch({
      type: HANDLE_CHANGE,
      customerList: editedCustomerList,
    });
  };
}

export function deleteCustomer(customer_id) {
  return function(dispatch, getState) {
    dispatch({
      type: DELETE_CUSTOMER,
      userDeleted: false,
      loadingDeleteCustomer: true,
      loadingGetCustomers: true,
      showUserNotBeDeleted: false,
      error: {
        status: false,
        message: "",
      },
    });
    return NetworkServices.requestData(
      "GET",
      Endpoints.deleteUserByUuid + "?uuid=" + customer_id,
      null,
      false,
      false
    )
      .then((response) => {
        dispatch(clearSearchFilter());
        if (response.data.code === 1) {
          return dispatch({
            type: DELETE_CUSTOMER,
            loadingDeleteCustomer: false,
            loadingGetCustomers: false,
            userDeleted: response.data.data,
            showUserNotBeDeleted: !response.data.data,
            searchObject: null,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_CUSTOMER,
          loadingDeleteCustomer: false,
          loadingGetCustomers: false,
          userDeleted: false,
          showUserNotBeDeleted: true,
          error: {
            status: false,
            message: "Error: ",
            error,
          },
        });
      });
  };
}

export function updateCustomerDB() {
  let editedCustomerList = [];
  return function(dispatch, getState) {
    const customerList = getState().customerDB.customerList;
    const customerListDB = getState().customerDB.customerListDB;
    for (let i = 0; i < customerList.length; i++) {
      if (
        JSON.stringify(customerList[i]) !== JSON.stringify(customerListDB[i])
      ) {
        // To send back-end only edited customers
        editedCustomerList.push(customerList[i]);
      }
    }
    dispatch({
      type: UPDATE_CUSTOMER,
      userUpdated: false,
      showUserNotBeUpdated: false,
      loadingUpdateCustomers: true,
      error: {
        status: false,
        message: "",
      },
    });
    return NetworkServices.requestData(
      "POST",
      Endpoints.updateUsers,
      editedCustomerList,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          return dispatch({
            type: UPDATE_CUSTOMER,
            userUpdated: response.data.data,
            showUserNotBeUpdated: !response.data.data,
            loadingUpdateCustomers: false,
            error: {
              status: false,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_CUSTOMER,
          userUpdated: false,
          loadingUpdateCustomers: false,
          showUserNotBeUpdated: true,
          error: {
            status: true,
            message: "Error: ",
            error,
          },
        });
      });
  };
}

export function getCustomers(isSearchClicked, getOneUser = false) {
  return function(dispatch, getState) {
    const storeCustomerDB = getState().customerDB;
    let currentPage = isSearchClicked
      ? 1
      : storeCustomerDB.pagination.currentPage;
    const requestBody =
      getState().customerDB.searchObject !== null
        ? {
            page: currentPage,
            pageSize: customerDatabasePageSize,
            searchType: getState().customerDB.searchObject.searchType,
            searchTerm: getState().customerDB.searchObject.searchTerm,
          }
        : {
            page: storeCustomerDB.pagination.currentPage,
            pageSize: customerDatabasePageSize,
          };
    dispatch({
      type: GET_CUSTOMERS,
      customerList: [],
      customerListDB: [],
      editedCustomers: [],
      loadingGetCustomers: true,
      showUserNotBeDeleted: false,
      showUserNotBeUpdated: false,
      showCannotDeleteActiveUsersError: false,
      error: {
        status: false,
        message: "",
      },
      pagination: _.cloneDeep(getState().customerDB.pagination),
      clearFilterButtonClicked: false,
    });
    return NetworkServices.requestData(
      "POST",
      getOneUser ? Endpoints.getOneUser : Endpoints.getPagedUsers,
      requestBody,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          return dispatch({
            type: GET_CUSTOMERS,
            customerList: response.data.data.results,
            customerListDB: response.data.data.results,
            loadingGetCustomers: false,
            showUserNotBeDeleted: false,
            showUserNotBeUpdated: false,
            showCannotDeleteActiveUsersError: false,
            userDeleted: false,
            userUpdated: false,
            error: {
              status: false,
            },
            pagination: {
              currentPage: response.data.data.currentPage,
              pageCount: response.data.data.pageCount,
              pageSize: response.data.data.pageSize,
              rowCount: response.data.data.rowCount,
              firstRowOnPage: response.data.data.firstRowOnPage,
              lastRowOnPage: response.data.data.lastRowOnPage,
            },
            clearFilterButtonClicked: false,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_CUSTOMERS,
          loadingGetCustomers: false,
          customerList: [],
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function showCannotDeleteActiveUsersError(status) {
  return function(dispatch) {
    return dispatch({
      type: SHOW_CANNOT_DELETE_ACTIVE_USER_ERROR,
      showCannotDeleteActiveUsersError: status,
      loadingDeleteCustomer: false,
      loadingGetCustomers: false,
    });
  };
}

export function changeActivePage(page) {
  return function(dispatch, getState) {
    if (page !== getState().customerDB.pagination.currentPage) {
      let paginationObj = getState().customerDB.pagination;
      paginationObj.currentPage = page;
      return dispatch({
        type: CHANGE_ACTIVE_PAGE,
        pagination: paginationObj,
      });
    }
  };
}

export function changeSearchTerm(searchType, searchTerm) {
  searchTerm = searchTerm === "" ? null : searchTerm;
  let customerDBSearchModel = _.cloneDeep(customerDBSearch);
  for (var property in customerDBSearchModel) {
    if (customerDBSearchModel[property].searchType === searchType) {
      customerDBSearchModel[property].searchTerm = searchTerm;
    } else {
      customerDBSearchModel[property].searchTerm = null;
    }
  }
  const searchObject =
    searchTerm === null
      ? null
      : {
          searchType: searchType,
          searchTerm: searchTerm,
        };
  return function(dispatch) {
    return dispatch({
      type: CHANGE_SEARCH_TERM,
      customerDBSearchModel: customerDBSearchModel,
      searchObject: searchObject,
      clearFilterButtonClicked: false,
    });
  };
}

export function clearSearchFilter() {
  return function(dispatch) {
    return dispatch({
      type: CLEAR_SEARCH_FILTER,
      customerDBSearchModel: _.cloneDeep(customerDBSearch),
      searchObject: null,
      clearFilterButtonClicked: true,
    });
  };
}

export function clearCustomerDBList() {
  return function(dispatch) {
    return dispatch({
      type: CLEAR_CUSTOMER_DB_LIST,
    });
  };
}

export function clearPagination() {
  return function(dispatch) {
    return dispatch({
      type: CLEAR_PAGINATION,
    });
  };
}
export function clearCustomer() {
  return function(dispatch) {
    return dispatch({
      type: CLEAR_CUSTOMER,
    });
  };
}
