import * as NetworkServices from "../services/networkServices";
import * as Endpoints from "../const/endpoints";
import { responseCode } from "../const/responseCode";
const GET_BOOKING_HISTORY = "reducer/GET_BOOKING_HISTORY";

export default (
  state = { history: null, historyLoader: false, historyError: null },
  action
) => {
  switch (action.type) {
    case GET_BOOKING_HISTORY:
      return {
        ...state,
        history: action.history,
        historyLoader: action.historyLoader,
        historyError: action.historyError,
      };
    default:
      return state;
  }
};

export function getHistory(userId, historyType) {
  return function(dispatch, getState) {
    dispatch({
      type: GET_BOOKING_HISTORY,
      history: null, //getMockData(historyType),
      historyLoader: true,
      historyError: null,
    });

    return NetworkServices.requestData(
      "POST",
      Endpoints.getBookingHistory,
      {
        userId: userId,
        categoryType: historyType,
      },
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          return dispatch({
            type: GET_BOOKING_HISTORY,
            history: response.data.data,
            historyLoader: false,
            historyError: null,
          });
        } else {
          return dispatch({
            type: GET_BOOKING_HISTORY,
            history: null,
            historyLoader: false,
            historyError: null,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_BOOKING_HISTORY,
          history: null,
          historyLoader: false,
          historyError: null,
        });
      });
  };
}
