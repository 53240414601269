import * as Endpoints from "../const/endpoints";
import * as NetworkServices from "../services/networkServices";
import { convertArrayBufferToBase64 } from "../helper/fileHelper";
import { removeMultipleSpaces } from "../helper/stringHelper";
import _ from "lodash";
import { uploadFile } from "./fileUpload";
import { handleValidationError } from "./modal";
import { responseCode } from "../const/responseCode";
import offer from "./offer";

// Actions
const GET_OFFER_CATEGORY_TYPES = "reducer/GET_OFFER_CATEGORY_TYPES";
const GET_OFFER_CATEGORIES = "reducer/GET_OFFER_CATEGORIES";
const UPDATE_OFFER_CATEGORY = "reducer/UPDATE_OFFER_CATEGORY";
const SAVE_SELECTED_OFFER_CATEGORY = "reducer/SAVE_SELECTED_OFFER_CATEGORY";
const EDIT_SELECTED_OFFER_CATEGORY = "reducer/EDIT_SELECTED_OFFER_CATEGORY";
const SAVE_CURRENT_OFFER_CATEGORIES = "reducer/SAVE_CURRENT_OFFER_CATEGORIES";
const CLEAR_STORED_OFFER_CATEGORIES = "reducer/CLEAR_STORED_OFFER_CATEGORIES";
const GET_LTR_DATALIST = "reducer/GET_LTR_DATALIST";
const GET_LTR_CARCLASS = "reducer/GET_LTR_CARCLASS";
const UPDATE_LTR_DATALIST = "reducer/UPDATE_LTR_DATALIST";

// Reducers
export default (
  state = {
    offerCategoryTypes: [],
    offerCategories: [],
    visibleSuccess: false,
    be_version: "",
    ltrDataList: [],
  },
  action
) => {
  switch (action.type) {
    case GET_OFFER_CATEGORY_TYPES:
      return {
        ...state,
        offerCategoryTypes: action.offerCategoryTypes,
        loadingOfferCategoryTypes: action.loadingOfferCategoryTypes,
        error: action.error,
        be_version: action.be_version,
      };
    case GET_OFFER_CATEGORIES:
      return {
        ...state,
        offerCategories: action.offerCategories,
        loadingOfferCategories: action.loadingOfferCategories,
        visibleSuccess: action.visibleSuccess,
        error: action.error,
      };
    case UPDATE_OFFER_CATEGORY:
      return {
        ...state,
        editedOfferCategory: _.cloneDeep(action.editedOfferCategory),
        selectedOfferCategory: _.cloneDeep(action.selectedOfferCategory),
        loadingUpdateOfferCategory: action.loadingUpdateOfferCategory,
        visibleSuccess: action.visibleSuccess,
        error: action.error,
      };
    case SAVE_SELECTED_OFFER_CATEGORY:
      return {
        ...state,
        selectedOfferCategory: action.selectedOfferCategory,
        editedOfferCategory: _.cloneDeep(action.selectedOfferCategory),
        error: action.error,
      };
    case EDIT_SELECTED_OFFER_CATEGORY:
      return {
        ...state,
        editedOfferCategory: action.editedOfferCategory,
        error: action.error,
      };
    case SAVE_CURRENT_OFFER_CATEGORIES:
      return {
        ...state,
        currentOfferCategories: action.currentOfferCategories,
        error: action.error,
      };
    case CLEAR_STORED_OFFER_CATEGORIES:
      return {
        ...state,
        editedOfferCategory: action.editedOfferCategory,
        selectedOfferCategory: action.selectedOfferCategory,
      };
    case GET_LTR_DATALIST:
      return {
        ...state,
        ltrDataList: action.ltrDataList,
        loadingLtrDataList: action.loadingLtrDataList,
        error: action.error,
      };
    case GET_LTR_CARCLASS:
      return {
        ...state,
        ltrCarClass: action.ltrCarClass,
        loadingLtrCarClass: action.loadingLtrCarClass,
      };
    case UPDATE_LTR_DATALIST:
      return {
        ...state,
        ltrDataList: action.ltrDataList,
        loadingLtrDataList: action.loadingLtrDataList,
        error: action.error,
        visibleSuccess: action.visibleSuccess,
      };

    default:
      return { ...state };
  }
};

// Action Creators

export function getOfferCategoryTypes() {
  return function(dispatch) {
    dispatch({
      type: GET_OFFER_CATEGORY_TYPES,
      offerCategoryTypes: [],
      loadingOfferCategoryTypes: true,
      be_version: "",
    });
    return NetworkServices.requestData(
      "GET",
      Endpoints.getOfferCategoryTypes,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === responseCode.success) {
          return dispatch({
            type: GET_OFFER_CATEGORY_TYPES,
            offerCategoryTypes: response.data.data,
            loadingOfferCategoryTypes: false,
            be_version:
              _.get(response.headers, "x-api-environment") +
              "-" +
              _.get(response.headers, "x-api-version"),
          });
        } else {
          return dispatch({
            type: GET_OFFER_CATEGORY_TYPES,
            offerCategoryTypes: [],
            loadingOfferCategoryTypes: false,
            be_version: "",
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_OFFER_CATEGORY_TYPES,
          offerCategoryTypes: [],
          loadingOfferCategoryTypes: false,
          error: {
            status: true,
            message: "Error: " + error,
          },
          be_version: "",
        });
      });
  };
}

export function getOfferCategories() {
  return function(dispatch) {
    dispatch({
      type: GET_OFFER_CATEGORIES,
      offerCategories: [],
      loadingOfferCategories: true,
    });
    let offerCategories = [];
    return NetworkServices.requestData(
      "GET",
      Endpoints.getOfferCategories,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.data && response.data.code === 1) {
          offerCategories = response.data.data;
          offerCategories.map((cat) => {
            cat.pause = cat.pause === 0 ? 1 : 0; // change pause status to display switch button
            if (cat.useCaseInformation == null) {
              cat.useCaseInformation = {
                isActive: false,
                translations: [
                  {
                    languageCode: "en",
                    headline: "",
                    informationText: "",
                  },
                  {
                    languageCode: "ko",
                    headline: "",
                    informationText: "",
                  },
                ],
              };
            } else {
              cat.useCaseInformation.translations = _.orderBy(
                cat.useCaseInformation.translations,
                "languageCode",
                "asc"
              );
            }
            return cat;
          });
          return dispatch({
            type: GET_OFFER_CATEGORIES,
            offerCategories: offerCategories,
            loadingOfferCategories: false,
            visibleSuccess: false,
          });
        } else {
          return dispatch({
            type: GET_OFFER_CATEGORIES,
            offerCategories: [],
            loadingOfferCategories: false,
            error: {
              status: true,
              message: "Error: " + response.data.message,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_OFFER_CATEGORIES,
          offerCategories: [],
          loadingOfferCategories: false,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function getImages(offerCategories) {
  let list = [];
  for (let i = 0; i < offerCategories.length; i++) {
    list.push(
      NetworkServices.requestImage(
        "GET",
        offerCategories[i].offerCategoryIcon,
        null
      )
    );
  }
  return Promise.all(list)
    .then((resList) => {
      for (let i = 0; i < offerCategories.length; i++) {
        offerCategories[i].offerCategoryIcon = convertArrayBufferToBase64(
          resList[i].data
        );
      }
      return offerCategories;
    })
    .catch((error) => {
      return error;
    });
}

export function updateOfferCategory(edited_offer_category, formData) {
  return function(dispatch, getState) {
    dispatch({
      type: UPDATE_OFFER_CATEGORY,
      loadingUpdateOfferCategory: true,
      selectedOfferCategory: getState().offerCategory.selectedOfferCategory,
      editedOfferCategory: edited_offer_category,
    });
    if (!formData.entries().next().done) {
      let uploadFileAction = dispatch(uploadFile(formData));
      let offerWithImages;
      return uploadFileAction.then((response) => {
        if (!response.error.status) {
          offerWithImages = getState().offerCategory.editedOfferCategory;
          let count = 0;
          if (
            offerWithImages.image instanceof File ||
            offerWithImages.image instanceof Object
          ) {
            offerWithImages.image = response.hashOfImages[count++];
          }
          dispatch(
            requestUpdateOfferCateory(
              Endpoints.updateOfferCategory,
              offerWithImages
            )
          );
        } else {
          return dispatch({
            type: UPDATE_OFFER_CATEGORY,
            loadingUpdateOfferCategory: false,
            editedOfferCategory: getState().offerCategory.editedOfferCategory,
            selectedOfferCategory: getState().offerCategory.editedOfferCategory,
            error: {
              status: true,
              message: "Error: " + response.error.message,
            },
          });
        }
      });
    } else {
      dispatch(
        requestUpdateOfferCateory(
          Endpoints.updateOfferCategory,
          edited_offer_category
        )
      );
    }
  };
}

export function saveSelectedOfferCategory(selectedOfferCategory) {
  let orderedTranslations = _.orderBy(
    selectedOfferCategory.translations,
    "languageCode",
    "asc"
  );
  selectedOfferCategory.translations = orderedTranslations;
  return function(dispatch) {
    dispatch({
      type: SAVE_SELECTED_OFFER_CATEGORY,
      selectedOfferCategory: selectedOfferCategory,
    });
  };
}

export function editSelectedOfferCategory(offerCategory) {
  return function(dispatch, getState) {
    dispatch({
      type: EDIT_SELECTED_OFFER_CATEGORY,
      editedOfferCategory: _.cloneDeep(offerCategory),
      error: {
        status: false,
        message: "",
      },
    });
  };
}

export function saveCurrentOfferCategories(currentOfferCategories) {
  return function(dispatch) {
    dispatch({
      type: SAVE_CURRENT_OFFER_CATEGORIES,
      currentOfferCategories: currentOfferCategories,
      error: {
        status: false,
        message: "",
      },
    });
  };
}

function requestUpdateOfferCateory(updateEndpoint, edited_offer_category) {
  return function(dispatch, getState) {
    edited_offer_category.pause = edited_offer_category.pause === 0 ? 1 : 0;
    let trimmedOfferCategory = removeMultipleSpaces(edited_offer_category);
    return NetworkServices.requestData(
      "POST",
      updateEndpoint,
      trimmedOfferCategory,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          dispatch(handleValidationError(false));
          let selectedOfferCategory = response.data.data;
          selectedOfferCategory.pause =
            selectedOfferCategory.pause === 0 ? 1 : 0;
          return dispatch({
            type: UPDATE_OFFER_CATEGORY,
            editedOfferCategory: selectedOfferCategory,
            selectedOfferCategory: _.cloneDeep(selectedOfferCategory),
            loadingUpdateOfferCategory: false,
            visibleSuccess: true,
          });
        } else {
          return dispatch({
            type: UPDATE_OFFER_CATEGORY,
            loadingUpdateOfferCategory: false,
            editedOfferCategory: edited_offer_category,
            selectedOfferCategory: getState().offerCategory
              .selectedOfferCategory,
            visibleSuccess: false,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_OFFER_CATEGORY,
          loadingUpdateOfferCategory: false,
          selectedOfferCategory: getState().offerCategory.selectedOfferCategory,
          editedOfferCategory: edited_offer_category,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function clearStoredOfferCategories() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_STORED_OFFER_CATEGORIES,
      editedOfferCategory: null,
      selectedOfferCategory: null,
    });
  };
}

export function getLtrDataList() {
  return function(dispatch) {
    dispatch({
      type: GET_LTR_DATALIST,
      ltrDataList: [],
      loadingLtrDataList: true,
    });
    let ltrDataList = [];
    return NetworkServices.requestData(
      "GET",
      Endpoints.getLtrCarModels,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.data && response.data.code === 1) {
          ltrDataList = response.data.data;

          return dispatch({
            type: GET_LTR_DATALIST,
            ltrDataList: ltrDataList,
            loadingLtrDataList: false,
            //visibleSuccess: false,
          });
        } else {
          return dispatch({
            type: GET_LTR_DATALIST,
            ltrDataList: [],
            loadingLtrDataList: false,
            error: {
              status: true,
              message: "Error: " + response.data.message,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_LTR_DATALIST,
          ltrDataList: [],
          loadingLtrDataList: false,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function getLtrCarClass() {
  return function(dispatch) {
    dispatch({
      type: GET_LTR_CARCLASS,
      ltrCarClass: [],
      loadingLtrCarClass: true,
    });
    let ltrCarClass = [];
    return NetworkServices.requestData(
      "GET",
      Endpoints.getLtrCarClasses,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.data && response.data.code === 1) {
          ltrCarClass = response.data.data;

          return dispatch({
            type: GET_LTR_CARCLASS,
            ltrCarClass: ltrCarClass,
            loadingLtrCarClass: false,
          });
        } else {
          return dispatch({
            type: GET_LTR_CARCLASS,
            ltrCarClass: [],
            loadingLtrCarClass: false,
            error: {
              status: true,
              message: "Error: " + response.data.message,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_LTR_CARCLASS,
          ltrCarClass: [],
          loadingLtrCarClass: false,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function updateLtrData(editedLtrList) {
  return function(dispatch, getState) {
    dispatch({
      type: UPDATE_LTR_DATALIST,
      ltrDataList: editedLtrList,
      loadingLtrDataList: false,
    });

    return NetworkServices.requestData(
      "POST",
      Endpoints.updateLtr,
      editedLtrList,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          dispatch(handleValidationError(false));
          return dispatch({
            type: UPDATE_LTR_DATALIST,
            ltrDataList: response.data.data,
            loadingLtrDataList: false,
            visibleSuccess: true,
          });
        } else {
          return dispatch({
            type: UPDATE_LTR_DATALIST,
            ltrDataList: editedLtrList,
            loadingLtrDataList: false,
            visibleSuccess: false,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_LTR_DATALIST,
          ltrDataList: editedLtrList,
          loadingLtrDataList: false,
          visibleSuccess: false,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
