import axios from "axios";
import * as Endpoints from "../const/endpoints";
import * as NetworkServices from "../services/networkServices";
import { responseCode } from "../const/responseCode";
import { handleResponseDataCode } from "../helper/errorHandler";
// Actions

const LOG_IN = "reducer/LOG_IN";
const LOG_OUT = "reducer/LOG_OUT";
const userInfoObj = {
  sub: "",
  authorization_group: "",
  entitlement_group: [],
  common_name: "",
  location_name: "",
  working_org_alias: "",
};
const SET_TOKENS = "reducer/SET_TOKEN";
const SET_USER_INFO = "reducer/SET_USER_INFO";
const RESET_AUTH = "reducer/SET_USER_INFO";
const SET_LOGIN = "reducer/SET_LOGIN";
const SET_EXCHANGE_STARTED = "reducer/SET_EXCHANGE_STARTED";

// Reducer
export default (
  state = {
    userLoggedIn: false,
    exchangeStarted: false,
    accessToken: null,
    refreshToken: null,
    expiresAt: null,
    userInfo: userInfoObj,
  },
  action
) => {
  switch (action.type) {
    case SET_TOKENS:
      return {
        ...state,
        userLoggedIn: true,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        expiresAt: action.expiresAt,
      };
    case SET_EXCHANGE_STARTED:
      return {
        ...state,
        exchangeStarted: action.exchangeStarted,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case RESET_AUTH:
      return {
        userLoggedIn: false,
        accessToken: null,
        refreshToken: null,
        userInfo: userInfoObj,
      };
    case SET_LOGIN:
      return {
        userLoggedIn: true,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        expiresAt: action.expiresAt,
        userInfo: action.userInfo,
      };
    default:
      return state;
  }
};

// Action Creators

export function setToken(accessToken, refreshToken, expiresAt) {
  return {
    type: SET_TOKENS,
    accessToken: accessToken,
    refreshToken: refreshToken,
    expiresAt: expiresAt,
  };
}

function setExchangeStarted(exchangeStarted) {
  return {
    type: SET_EXCHANGE_STARTED,
    exchangeStarted,
  };
}

export function exchangeToken(authCode) {
  return async function(dispatch, getState) {
    let exchangeStarted = getState().auth.exchangeStarted;
    if (!exchangeStarted) {
      dispatch(setExchangeStarted(true));
      //console.log(`${Endpoints.baseUrl}/${Endpoints.exchangeToken}`);
      let exchangeResponse = await axios.post(
        `${Endpoints.baseUrl}/${Endpoints.exchangeToken}`,
        { authCode }
      );
      let {
        access_token,
        refresh_token,
        expires_in,
      } = exchangeResponse.data.data;
      let timeout =
        expires_in && parseInt(expires_in) > 60
          ? (parseInt(expires_in) - 60) * 1000
          : 1739000;
      let expiresAt = Math.floor(Date.now() / 1000) + timeout / 1000;

      let userInfoResponse = await axios.get(
        `${Endpoints.baseUrl}/${Endpoints.userInfo}`,
        { headers: { "X-AccessToken": access_token } }
      );
      if (
        userInfoResponse.data.data.entitlement_group === null ||
        userInfoResponse.data.data.entitlement_group.length === 0
      ) {
        handleResponseDataCode({ code: responseCode.unauthorizedAdminError });
      }

      dispatch(
        setLogin(
          access_token,
          refresh_token,
          expiresAt,
          userInfoResponse.data.data
        )
      );

      setTimeout(() => refreshToken()(dispatch, getState), timeout);
    }
  };
}

export function setLogin(accessToken, refreshToken, expiresAt, userInfo) {
  return {
    type: SET_LOGIN,
    accessToken,
    refreshToken,
    expiresAt,
    userInfo,
  };
}

export function refreshToken() {
  return async function(dispatch, getState) {
    try {
      let response = await axios.post(
        `${Endpoints.baseUrl}/${Endpoints.refreshToken}`,
        {
          access_token: getState().auth.accessToken,
          refresh_token: getState().auth.refreshToken,
        }
      );
      if (response.data.code === responseCode.success) {
        let timeout =
          response.data.data.expires_in && response.data.data.expires_in > 60
            ? (response.data.data.expires_in - 60) * 1000
            : 1739000;
        let expiresAt = Math.floor(Date.now() / 1000) + timeout / 1000;

        dispatch(
          setToken(
            response.data.data.access_token,
            response.data.data.refresh_token,
            expiresAt
          )
        );
        setTimeout(() => dispatch(refreshToken()), timeout);
      } else {
        window.location.href = `${Endpoints.baseUrl}/${Endpoints.login}`;
        //let loginResponse = await axios.get(
        // `${Endpoints.baseUrl}/${Endpoints.login}`
        // { headers: { "X-AccessToken": access_token } }
        // );
        //console.log("login response",loginResponse);
        //window.location.href = loginResponse.data;
      }
    } catch {
      window.location.href = `${Endpoints.baseUrl}/${Endpoints.login}`;
    }
  };
}

export function userInfo() {
  return async function(dispatch) {
    const response = await NetworkServices.requestData(
      "GET",
      Endpoints.userInfo,
      null,
      false,
      false
    );
    const resData = response.data;
    if (
      resData.code === responseCode.unauthorizedAdminError ||
      resData.data == null
    ) {
      dispatch({
        type: SET_USER_INFO,
        userInfo: userInfoObj,
      });
    } else {
      if (
        resData.data.entitlement_group === null ||
        resData.data.entitlement_group.length === 0
      ) {
        handleResponseDataCode({ code: responseCode.unauthorizedAdminError });
      }
      dispatch({
        type: SET_USER_INFO,
        userInfo: resData.data,
      });
    }
  };
}

export function resetAuth() {
  return { type: RESET_AUTH };
}

export function logOut() {
  return async function(dispatch) {
    dispatch(resetAuth());
    let response = await NetworkServices.requestData(
      "GET",
      Endpoints.logout,
      null,
      false,
      false
    );
    window.location.href = response.data.data;
  };
}
